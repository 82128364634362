import { parsePhoneNumberFromString } from 'libphonenumber-js';

window.GetParsedPhoneNumber = function (phoneNumber) {
    const phone = parsePhoneNumberFromString(phoneNumber,
        {
            defaultCountry: 'SE'
        });

    if (!phone || !phone.isValid()) {
        return "";
    }

    return phone.number;
}

window.parsePhoneNumberOnBlur = function () {
    const phone = parsePhoneNumberFromString(this.value,
        {
            defaultCountry: 'SE'
        });

    if (phone && phone.isValid()) {
        this.value = phone.number;
        this.dispatchEvent(new Event('change'));
    }
}

window.InitAsYouTypeForTelInput = function (telInput) {
    telInput.removeEventListener('blur', parsePhoneNumberOnBlur);
    telInput.addEventListener('blur', parsePhoneNumberOnBlur);
}

window.ReInitAllTelInputs = function () {
    var phoneInputs = document.querySelectorAll('input[type="tel"]');
    phoneInputs.forEach(function (input) {
        InitAsYouTypeForTelInput(input);
    });
}

document.addEventListener('DOMContentLoaded', ReInitAllTelInputs);


